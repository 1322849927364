export const endpoints={
    'GET-Models':'https://api.prompt-repository.incubation-station.com/v1/models',
    'POST-Generate-Prompt':'https://api.prompt-repository.incubation-station.com/v1/generate',
    'POST-Generate-Prompt-AI':'https://api.prompt-repository.incubation-station.com/v1/generate/ai',
    'GET-me':'https://api.prompt-repository.incubation-station.com/v1/me',
    'POST-Create-Template':'https://api.prompt-repository.incubation-station.com/v1/template',
    'GET-Template':'https://api.prompt-repository.incubation-station.com/v1/template/',
    'GET-Templates':'https://api.prompt-repository.incubation-station.com/v1/templates',
    'DELETE-Template':'https://api.prompt-repository.incubation-station.com/v1/template/',
    'DELETE-Version':'https://api.prompt-repository.incubation-station.com/v1/version/',
    'PATCH-Update-Template':'https://api.prompt-repository.incubation-station.com/v1/template/',
    'GET-Versions':'https://api.prompt-repository.incubation-station.com/v1/versions/',
    'GET-Version':'https://api.prompt-repository.incubation-station.com/v1/version/',
    'POST-Action':'https://api.prompt-repository.incubation-station.com/v1/action',
    'GET-Task':'https://api.prompt-repository.incubation-station.com/v1/task/',
    'PUT-Template':'https://api.prompt-repository.incubation-station.com/v1/template/',
    
}