import { useContext, createContext, useState, Children } from "react";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../env_data/endpoints";

const FormValueContext = createContext();

function FormValueProvider({ children }) {
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  const redirectError = (res) => {
    let errorMsg = JSON.stringify(res)
    console.log("oops" + errorMsg)
    navigate("/oops", { state: { errorMsg: errorMsg } });
  }

  const getModelsAction = async (apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Models"], {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json",
        }
      });
      const res = await response.json();
      if (res) {
        let modelArr = []
        res.forEach(element => {
          let model = {
            name: "",
            provider: "",
            id: "",
            isChecked: false
          }
          model.name = element["name"]
          model.provider = element["provider"]
          model.id = element["id"]
          modelArr.push(model)
        });
        setModels(modelArr);
        return modelArr;
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const generatePrompt = async (reqKeys, apiKey) => {
    reqKeys = JSON.stringify(reqKeys)

    try {
      const response = await fetch(endpoints["POST-Generate-Prompt"], {
        method: "POST",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: reqKeys
      });
      const res = await response.json();
      if (res) {
        return res.template;
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const generatePromptAI = async (reqKeys, apiKey) => {
    reqKeys = JSON.stringify(reqKeys)

    try {
      const response = await fetch(endpoints["POST-Generate-Prompt-AI"], {
        method: "POST",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: reqKeys
      });
      const res = await response.json();
      if (res) {
        return res.template;
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const createNewTemplate = async (template, apiKey) => {
    try {
      const response = await fetch(endpoints["POST-Create-Template"], {
        method: "POST",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(template)
      });
      const res = await response.json();
      if (res) {
        return res.template_id;
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const updateTemplate = async (template_id, template, apiKey) => {
    try {
      const response = await fetch(endpoints["PATCH-Update-Template"] + template_id, {
        method: "PATCH",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(template)
      });
      const res = await response.json();
      if (res) {
        return res.template_id;
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const getTemplate = async (template_id, apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Template"] + template_id, {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const getTemplates = async (apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Templates"], {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const deleteTemplate = async (template_id, apiKey) => {
    try {
      const response = await fetch(endpoints["DELETE-Template"] + template_id, {
        method: "DELETE",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const getVersions = async (template_id, apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Versions"] + template_id, {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const getVersion = async (template_id, apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Version"] + template_id, {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const deleteVersion = async (version_id, apiKey) => {
    try {
      const response = await fetch(endpoints["DELETE-Version"] + version_id, {
        method: "DELETE",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const putTemplate = async (data, template_id, apiKey) => {
    try {
      const response = await fetch(endpoints["PUT-Template"] + template_id, {
        method: "PUT",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: data
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
    }
  }

  const postAction = async (data, apiKey) => {
    try {
      const response = await fetch(endpoints["POST-Action"], {
        method: "POST",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        },
        body: data
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
      navigate("/oops");
    }
  }

  const getTask = async (task_id, apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Task"] + task_id, {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json"
        }
      });
      const res = await response.json();
      if (res) {
        if (res.ERROR_400) {
          redirectError(res)
        }
        else {
          return res;
        }
      }
      else {
        console.log("oops")
        navigate("/oops");
      }
      throw new Error(res.message);
    } catch (err) {
      console.log(err);
      navigate("/oops");
    }
  }

  return (
    <FormValueContext.Provider value={{ getModelsAction, generatePrompt, createNewTemplate, getTemplate, getTemplates, deleteTemplate, updateTemplate, getVersions, getVersion, deleteVersion, postAction, getTask, putTemplate, generatePromptAI }}>
      {children}
    </FormValueContext.Provider>
  );
};

export default FormValueProvider;
export const useFormValue = () => {
  return useContext(FormValueContext);
};