export const keyValInputs = [{
    name: "role",
    isChecked: false
  }, {
    name: "tone",
    isChecked: false
  }, {
    name: "expected_output",
    isChecked: false
  }, {
    name: "context",
    isChecked: false
  }]