import { Routes, Route } from "react-router-dom"
import './App.css';
import Home from './pages/Home';
import Auth from './pages/Auth';
import Oops from './pages/Oops';
import AuthProvider from './services/AuthProvider';
import TemplatePage from './pages/TemplatePage';
import TemplateRunPage from './pages/TemplateRunPage';
import TemplateLibraryPage from './pages/TemplateLibraryPage';
import TemplateEditPage from './pages/TemplateEditPage';
import VersionEditPage from './pages/VersionEditPage';
import TemplateVersionHistoryPage from './pages/TemplateVersionHistoryPage';
import TemplateResultPage from './pages/TestResultPage';
function App() {
  return (
    <div className="App">
      <AuthProvider>
      <Routes>
      <Route default path="/auth" element={ <Auth/> } />
      <Route path="/template-create" element={ <Home/> } />
      <Route path="/template-list" element={ <TemplatePage/> } />
      <Route path="/template-library" element={ <TemplateLibraryPage/> } />
      <Route path="/template-run/:template_id" element={ <TemplateRunPage/> } />
      <Route path="/template-edit/:template_id" element={ <TemplateEditPage/> } />
      <Route path="/version-edit/:template_id" element={ <VersionEditPage/> } />
      <Route path="/template-version-history/:template_id" element={ <TemplateVersionHistoryPage/> } />
      <Route path="/template-result/:task_ids" element={ <TemplateResultPage/> } />

      <Route path="/oops" element={ <Oops/> } />
      <Route path="*" element={<Oops />} />
      </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
