import React from 'react';
import { useLocation } from "react-router-dom";
import '../index.css';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
import TestTemplateResult from '../components/TestTemplateResult';


function TemplateResultPage() {
  const { state } = useLocation();
  const { callData } = state;
  return (
    <div>
      <Navbar></Navbar>
      <FormValueProvider>
        <TestTemplateResult callData={callData} ></TestTemplateResult>
      </FormValueProvider>
    </div>
  );
}

export default TemplateResultPage;