import React, { useEffect, useState } from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import TemplateVersionTable from '../components/TemplateVersionTable';
import FormValueProvider from '../services/FormValueProvider';

function TemplateHistoryPage() {

  return (
    <div>
      <Navbar></Navbar>
      <FormValueProvider>
        <h2>Template History Page</h2>
        <TemplateVersionTable ></TemplateVersionTable>
      </FormValueProvider>
    </div>
  );
}

export default TemplateHistoryPage;