import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import '../style/template.css'
import '../style/shared.css';
import { Button, FormField, InputField, InputArea, CheckBox, Card, StatusLabel } from '@blueprism/ui-core';
import { useFormValue } from '../services/FormValueProvider';
import { useNavigate } from "react-router-dom";

function TemplateRun() {
  const [models, setModels] = React.useState([])
  const [additionalDataValue, setAdditionalDataValue] = React.useState('');
  const [, setLoadingModels] = useState(true);
  const [, setLoadingTemplate] = useState(true);
  const [, setValue] = useState(0);
  const [selectedModels, setSelectedModels] = useState([])
  const [template, setTemplate] = useState({})
  const [testTemplateFormData, setTestTemplateFormData] = useState({})
  const [taskIds, setTaskIds] = React.useState([])
  const [modelsTaskAndTemp, setModelsTaskAndTemp] = React.useState([])
  const formreq = useFormValue();
  const navigate = useNavigate();
  const { template_id } = useParams();
  const providers = {
    bedrock: [],
    openai: []
  }

  // HANDLERS
  function handleAdditionalDataValueChange(e) {
    setAdditionalDataValue(e.target.value);
  }

  function handleModelCheckChange(e) {
    e.preventDefault();
    let modelName = e.target.value;
    let model = models.find((i => i["name"] === modelName))
    model['isChecked'] = !model["isChecked"]
    let modelIndex = models.findIndex(m => m["name"] === modelName)
    models[modelIndex] = model
    setModels(models)
    if (model['isChecked'] === true) {
      selectedModels.push(model)
      setSelectedModels(selectedModels)
    }
    else if (model['isChecked'] === false) {
      let index = selectedModels.findIndex((i => i["name"] === modelName))
      selectedModels.splice(index, 1)
      setSelectedModels(selectedModels)
    }
    setValue(value => value + 1);
    return;
  }

  function handleInputDataChange(e) {
    e.preventDefault()
    let testTemplateInput = e.target.value;
    let testTemplateInputName = e.target.name
    testTemplateFormData[testTemplateInputName] = testTemplateInput
    setTestTemplateFormData(testTemplateFormData)
    return;
  }

  function assignVals() {
    let InputOptions;

    if (template['required_keys']) {
      var index = template['required_keys'].indexOf("additional_data");
      if (index !== -1) {
        template['required_keys'].splice(index, 1);
        setTemplate(template)
      }
      InputOptions = (<div>
        {template['required_keys'].map((m) =>
          <FormField label={m} requiredLabel="Required">
            <InputField name={m} id={m} onChange={handleInputDataChange} />
          </FormField>)}
      </div>)
    }
    return InputOptions;
  }

  const getModels = () => {
    formreq.getModelsAction(localStorage.getItem("site")).then((response) => {
      setModels(response)
      setLoadingModels(false);
      sortModelsByProvider()
    }
    )
  }

  const getTemplate = (template_id) => {
    formreq.getTemplate(template_id, localStorage.getItem("site")).then((response) => {
      setTemplate(response)
      setLoadingTemplate(false)

    })
  }

  function testTemplate(e) {
    e.preventDefault();
    let LLMcount = 1;
    selectedModels.forEach((model) => {
      let data = testTemplateFormData
      data.additional_data = additionalDataValue
      data.llm_model_id = model.id
      data.template_id = template_id
      data = JSON.stringify(data)
      formreq.postAction(data, localStorage.getItem("site")).then((response) => {
        let callObj = {
          template_id: template_id,
          template_name: template['name'],
          template_prompt: template['prompt'],
          model_name: model.name,
          loading: true,
          task_id: response["task_id"]
        }
        modelsTaskAndTemp.push(callObj)
        setModelsTaskAndTemp(modelsTaskAndTemp)
        taskIds.push(response["task_id"])
        setTaskIds(taskIds)
        if (LLMcount === selectedModels.length) {
          navigateToResult(taskIds)
        }
        else {
          LLMcount++;
        }
      })
    })
  }

  const navigateToResult = (taskIds) => {
    if (taskIds.length > 1) {
      taskIds = taskIds.join('+')
    }
    navigate("/template-result/" + taskIds.toString(), { state: { callData: modelsTaskAndTemp } })
  }

  // CONDITIONAL HTML RENDERING
  const displayButton = () => {
    if (selectedModels.length >= 1 && selectedModels.length <= 3) {
      return <Button importance="primary" id="testTemplateBtn" method="post" type="submit" onClick={testTemplate} >Test Template</Button>
    }
    else if (selectedModels.length > 3) {
      return <div className='tooManyLLMs'> <Button id="disabledButton" importance="primary" disabled>Test Template</Button>
        <StatusLabel variant="danger">Error: Select up to 3 LLMs</StatusLabel>
      </div>
    }
    else if (selectedModels.length === 0) {
      return <Button importance="primary" disabled>Test Template</Button>
    }
  }

  function sortModelsByProvider() {
    models.forEach((m) => {
      providers[m.provider].push(m)
    })

    let openaiModels = (<div className='openAI'>
      <FormField label="OpenAI Models:">
        {providers.openai.map((m) => <CheckBox name="model" label={m.name} value={m.name} checked={m["isChecked"]} onChange={handleModelCheckChange} />)}
      </FormField>
    </div>)

    let bedrockModels = (<div>
      <FormField label="AWS Bedrock Models:">
        {providers.bedrock.map((m) => <CheckBox name="model" label={m.name} value={m.name} checked={m["isChecked"]} onChange={handleModelCheckChange} />)}
      </FormField>
    </div>)

    let sortedModels = (<div className='flex-row sorted-models'>
      {openaiModels}
      {bedrockModels}
    </div>)
    return sortedModels;
  }

  useEffect(() => {
    setLoadingModels(true);
    setLoadingTemplate(true);
    getModels();
    getTemplate(template_id);
  }, [template_id]);

  return (
    <div className='templateRun'>
      <div className='templateRunDesc'>
        <h2>You Have seleceted <b><a>{template['name']}</a></b> as your prompt template to run.<br></br> <br></br> Please select the Model(s) you would like to send this and your data to:</h2>
        <Card padding="medium" width="auto" height="auto" raised rounded>
          Prompt: <b>{template['template']}</b>
        </Card>
      </div>
      <div className='flex-row runTemplate'>
        <div className='keyValForm'>
          <form method="post" onSubmit={testTemplate} className='template-form-run flex-col'>
            <div className='flex-row inputs'>
              <Card padding="medium" raised rounded height='auto'>
                <div className='flex-col keyValInputs'>
                  {assignVals()}
                </div>
              </Card>
            </div>
          </form>
        </div>
        <div className='additionalDataRun'>
          <FormField label="Additional Data" helperText="Paste any plain text, JSON, or XML value to be sent to the LLM of choice. This data is NOT stored by us.">
            <InputArea value={additionalDataValue} onChange={handleAdditionalDataValueChange} name="additionaldata" id="additionalDataRun" />
          </FormField>
        </div>
        <div className='flex-col selectModels'>
          <FormField label="Select Model">
            {sortModelsByProvider()}
          </FormField>
        </div>
        <div className='flex-col testTemplate'>
          {displayButton()}
        </div>
      </div>
    </div>
  );
}

export default TemplateRun;