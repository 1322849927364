import React from 'react';
import '../index.css';
import TemplateInputForm from '../components/TemplateInputForm';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
function Home() {
  return (
    <div>
      <Navbar></Navbar>
      <h1>Create a Prompt Template</h1>
      <FormValueProvider>
        <TemplateInputForm template_id=''></TemplateInputForm>
      </FormValueProvider>


    </div>
  );
}

export default Home;