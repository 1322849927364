import React from 'react';
import '../style/auth.css';
import { useLocation } from "react-router-dom";
import { Card } from '@blueprism/ui-core';

function Oops() {
    const { state } = useLocation();
    const { errorMsg } = state;
    return (
        <div className='flex-col oops'>
            <h1>Oops, it looks like the following error has occured:</h1>
            <div className='errorMsgCard'>
                <Card padding="medium" width="auto" height="auto" raised rounded>
                    {errorMsg}
                </Card>
            </div>
            <h5>If youve been logged out, click</h5>
            <a href='/auth'><h5>Here</h5></a>
            <h5>to log back in.</h5>

        </div>
    );
}

export default Oops;