import React, { useEffect, useState } from 'react';
import '../index.css';
import '../style/template.css'
import '../style/shared.css';
import { Stack, ToolbarButton } from '@blueprism/ui-core';
import { Table, TableToolbar, useSelectedRows } from '@blueprism/ui-pattern-table';
// import { ToolbarButton } from '@blueprism/ui-pattern-toolbar';
import { Page, TriangleInCircle } from '@blueprism/ui-icons';
import { useFormValue } from '../services/FormValueProvider';
import FormValueProvider from '../services/FormValueProvider';
import { endpoints } from '../env_data/endpoints';
import { fakeTemplates } from '../env_data/fakeTemplates';
import { useNavigate } from "react-router-dom";

// NOTE TO SELF:
// add a react state for the row data and add functions to sort it when headers are clicked

const columns = [
  {
    header: 'Template Name',
    accessor: 'name',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  }, {
    header: 'Created',
    accessor: 'created_at',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  },
  {
    header: 'Last Updated',
    accessor: 'last_updated',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  },
]

function onHeaderClick(e) {
  e.preventDefault();
}

function TemplateLibrary() {
  const {
    selectedRowIds,
    noRowsSelected,
    singleRowSelected,
    handleRowSelectionChange,
    handleCheckboxStatusChange
  } = useSelectedRows([]);
  return (
    <div className='TemplateLibrary templateTable'>
      <Stack gap="base">
        <TableToolbar customContextualButtons={<ToolbarButton label="Test Template" icon={<TriangleInCircle />} disabled={noRowsSelected || !singleRowSelected} onClick={''} />}
          predefinedGlobalActions={{
          }} predefinedContextualActions={{
            edit: {
              label: 'Edit Template',
              disabled: noRowsSelected || !singleRowSelected,
              onClick: ''
            },
            view: {
              label: 'View Template',
              disabled: noRowsSelected || !singleRowSelected,
              onClick: ''
            }
          }}
        />
        <Table rowData={fakeTemplates} columns={columns} sortColumns selectedIds={selectedRowIds} selectRows onRowSelectionChange={handleRowSelectionChange} onHeaderCheckboxClick={handleCheckboxStatusChange} />
      </Stack>
    </div>
  );
}

export default TemplateLibrary;