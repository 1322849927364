import React from 'react';
import '../style/auth.css';
import { Button, FormField, InputField } from '@blueprism/ui-core';
import { useState } from "react";
import { useAuth } from '../services/AuthProvider';

const Auth = () => {
    const [input, setInput] = useState({
        apiKey: ""
    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const auth = useAuth();
    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (input.authKey !== "") {
            auth.loginAction(input.apiKey);
            return;
        }
        alert("pleae provide a valid input");
    };
    return (
        <div className='flex-col authPage'>
            <h1>Welcome to the Prompt Designer</h1>
            <form method="post" onSubmit={handleSubmitEvent} className='flex-col'>
                <FormField label="Input your APi/Authentication Key:">
                    <InputField name="apiKey" id="apiKey" type="password" onChange={handleInput} />
                </FormField>
                <br></br>
                <Button type="submit" importance="primary">Log In</Button>
            </form>
        </div>
    );
}

export default Auth;