import React from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
import TemplateRun from '../components/TemplateRun';
function TemplateRunPage() {
  return (
    <div>
      <Navbar></Navbar>
      <FormValueProvider>
        <TemplateRun></TemplateRun>
      </FormValueProvider>
    </div>
  );
}

export default TemplateRunPage;