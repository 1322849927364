import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import '../index.css';
import '../style/template.css'
import '../style/shared.css';
import { Button, FormField, CheckBox, InputField, InputArea, StatusLabel, Card, Callout } from '@blueprism/ui-core';
import { useFormValue } from '../services/FormValueProvider';
import { useNavigate } from "react-router-dom";
import { keyValInputs as fakeKeyValInputs } from '../env_data/fakeKeyValInputs';

function TemplateInputForm(props) {
  const [generatedPromptValue, setGeneratedPromptValue] = React.useState('Your generated prompt will be displayed here once submitted');
  const [templateNameValue, setTemplateNameValue] = React.useState('');
  const [versionNameValue, setVersionNameValue] = React.useState('');
  const [queryValue, setQueryValue] = React.useState('');
  const [queryValueError, setQueryValueError] = React.useState(false);
  const [temaplateNameValueError, setTemplateNameValueError] = React.useState(false);
  const [versionNameValueError] = React.useState(false);
  const [savedTemplate, setSavedTemplate] = React.useState(false)
  const [savedTemplateId, setSavedTemplateId] = React.useState('')
  const [templateFormData, setTemplateFormData] = React.useState({})
  const [templateStatus, setTemplateStatus] = React.useState('')
  const [, setValue] = useState(0);
  const [keyValInputs, setKeyValInputs] = React.useState(["query"]);
  const [, setSelectedKeyValInputs] = React.useState([]);
  const formreq = useFormValue();
  const navigate = useNavigate();
  const { template_id } = useParams();

  //FORM HANDLING FUNCTIONS
  function handleKeyValCheckChange(e) {
    // e.preventDefault();
    let keyValNameChecked = e.target.value;
    let keyValName = keyValInputs.find((i => i["name"] === keyValNameChecked))
    keyValName['isChecked'] = !keyValName["isChecked"]
    let keyValIndex = keyValInputs.findIndex(m => m["name"] === keyValNameChecked)
    keyValInputs[keyValIndex] = keyValName
    //DONT GET RID OF LINE BELOW!!! 
    //It is the easiest way to force an indepenedent re-render of that data since the stuff above is nested in objs
    setValue(value => value + 1);
    return;
  }
  function handleQueryValueChange(e) {
    setQueryValue(e.target.value);
  }
  function handleGeneratedPromptValueChange(e) {
    setGeneratedPromptValue(e.target.value);
  }
  function handleTemplateNameValue(e) {
    setTemplateNameValue(e.target.value);
  }
  function handleVersionNameValue(e) {
    setVersionNameValue(e.target.value);
  }
  // function onlyUnique(value, index, array) {
  //   return array.indexOf(value) === index;
  // }

  //PROMPT GENERATING AND TEMPLATE SUBMIT
  const generatePrompt = () => {
    let payload = {}
    let uniqueKeys = []
    //get selected key val inputs
    keyValInputs.forEach((val) => { if (val.isChecked === true) { uniqueKeys.push(val.name) } })

    payload.query = queryValue
    uniqueKeys.forEach((key) => {
      payload[key] = ''
    })
    formreq.generatePrompt(payload, localStorage.getItem("site")).then((response) => {
      setGeneratedPromptValue(response)
    }
    )
  }
  function submitTemplate(e) {
    e.preventDefault();
    if (queryValue === '') {
      setQueryValueError(true)
      return;
    }
    else {
      setQueryValueError(false)
    }
    if (templateNameValue === '') {
      setTemplateNameValueError(true)
      return;
    }
    else {
      setTemplateNameValueError(false)
    }
    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    setTemplateFormData(formJson)
    generatePrompt()
  }

  function saveTemplate(e) {
    e.preventDefault();
    if (templateStatus === 'Create') {
      let template = {
        name: templateNameValue,
        template: generatedPromptValue
      }
      formreq.createNewTemplate(template, localStorage.getItem("site")).then((response) => {
        setSavedTemplate(true)
        setSavedTemplateId(response)
      })
      setTemplateFormData(templateFormData)
    }
    else if (templateStatus === 'TemplateEdit') {
      //make the endpoint and come back to this
      let template = {
        name: templateNameValue,
        version: versionNameValue,
        template: generatedPromptValue
      }
      formreq.updateTemplate(template_id, template, localStorage.getItem("site")).then((response) => {
        setSavedTemplate(true)
        setSavedTemplateId(response)
      })
    }
    else if (templateStatus === 'VersionEdit') {
      let template = {
        name: templateNameValue,
        version: versionNameValue,
        template: generatedPromptValue
      }
      formreq.updateTemplate(template_id, template, localStorage.getItem("site")).then((response) => {
        setSavedTemplate(true)
        setSavedTemplateId(response)
      })
    }
  }

  function runSavedTemplate(e) {
    e.preventDefault();
    navigate("/template-run/" + savedTemplateId)
  }

  function viewMyTemplates(e) {
    e.preventDefault()
    navigate("/template-list")
  }

  //FORM VALIDATION
  const queryValueInput = () => {
    if (queryValueError === false) {
      return <div className='additionalData query'>
        <FormField label="Query" helperText="Required: add a query you'd like to as the LLM regarding your data.">
          <InputArea value={queryValue} onChange={handleQueryValueChange} name="queryValue" id="queryValue" />
        </FormField>
      </div>
    }
    if (queryValueError === true) {
      return <div className='additionalData query'>
        <FormField label="Query" helperText="Required: add a query you'd like to as the LLM regarding your data." >
          <InputArea value={queryValue} onChange={handleQueryValueChange} name="queryValue" id="queryValue" />
          <StatusLabel variant="danger">Error: Please add a query</StatusLabel>
        </FormField>
      </div>
    }
  }

  const templateNameInput = () => {
    if (temaplateNameValueError === false) {
      return <div className=''>
        <FormField requiredLabel="Required">
          <InputField name="template-name" id="template-name" value={templateNameValue} onChange={handleTemplateNameValue} />
        </FormField>
      </div>
    }
    if (temaplateNameValueError === true) {
      return <div className=''>
        <FormField requiredLabel="Required">
          <InputField name="template-name" id="template-name" value={templateNameValue} onChange={handleTemplateNameValue} />
          <StatusLabel variant="danger">Error: Please add a Template Name</StatusLabel>
        </FormField>
      </div>
    }
  }
  const versionNameInput = () => {
    if (templateStatus === 'TemplateEdit' || templateStatus === "VersionEdit") {
      if (versionNameValueError === false) {
        return <div className=''>
          <StatusLabel variant='warning'>
            Version Name:
          </StatusLabel>
          <FormField requiredLabel="Required">
            <InputField name="version-name" id="version-name" value={versionNameValue} onChange={handleVersionNameValue} />
          </FormField>
        </div>
      }
      if (versionNameValueError === true) {
        return <div className=''>
          <StatusLabel variant='warning'>
            Version Name:
          </StatusLabel>
          <FormField requiredLabel="Required">
            <InputField name="version-name" id="version-name" value={versionNameValue} onChange={handleVersionNameValue} />
            <StatusLabel variant="danger">Error: Please add a Version Name</StatusLabel>
          </FormField>
        </div>
      }
    }

  }

  //determine if edit or create
  const checkTemplateId = () => {
    setKeyValInputs(fakeKeyValInputs)
    if (props.templateStatus === "versionEdit") {
      setTemplateStatus("VersionEdit")
      getEditVersionData(template_id)
    }
    if (props.templateStatus === "templateEdit") {
      setTemplateStatus('TemplateEdit')
      getEditTemplateData(template_id)
    }
    else {
      setTemplateStatus('Create')
    }
  }

  //EDIT RELATED FUNCTIONS
  const getEditTemplateData = (template_id) => {
    formreq.getTemplate(template_id, localStorage.getItem("site")).then((response) => {
      setTemplateNameValue(response.name)
      setGeneratedPromptValue(response.template)
      response.required_keys.forEach((req_key) => {
        for (let i = 0; i < keyValInputs.length; i++) {
          if (keyValInputs[i]["name"] === req_key) {
            keyValInputs[i]["isChecked"] = true
          }
        }
      })
      setSelectedKeyValInputs(response.required_keys)
    })
  }
  const getEditVersionData = (template_id) => {
    formreq.getVersion(template_id, localStorage.getItem("site")).then((response) => {
      setTemplateNameValue(response.name)
      setGeneratedPromptValue(response.template)
      response.required_keys.forEach((req_key) => {
        try {
          let keyValName = keyValInputs.find((i => i["name"] === req_key))
          keyValName['isChecked'] = true
          let keyValIndex = keyValInputs.findIndex(m => m["name"] === req_key)
          keyValInputs[keyValIndex] = keyValName
          setKeyValInputs(keyValInputs)
        }
        catch { }
      })
      setSelectedKeyValInputs(response.required_keys)
    })
  }

  useEffect(() => {
    setKeyValInputs(fakeKeyValInputs);
    checkTemplateId(template_id);
  }, [template_id]);

  return (
    <div className=''>
      <div className='template-name'>
        <StatusLabel variant='information'>
          Template Name:
        </StatusLabel>
        {templateNameInput()}
        {versionNameInput()}
      </div>
      <div className='flex-row margin-right'>
        <form method="post" onSubmit={submitTemplate} className='template-form flex-col'>
          <div className='flex-row inputs'>
            <Card padding="medium" raised rounded width="auto" height='auto'>
              <div className='flex-col primaryInputs'>
                <div>
                  <div className='keyValCheckboxes'>
                    <FormField label="Select Keys which youd like to add values">
                      {keyValInputs.map((m) => <CheckBox name="keyvalinput" label={m["name"]} value={m["name"]} checked={m["isChecked"]} onChange={handleKeyValCheckChange} />)}
                    </FormField>
                  </div>
                </div>
                <br></br>
              </div>
              <div className='flex-col'>
                {queryValueInput()}
                <br></br>
                <Button id="generateButton" type="submit" importance="primary" >Generate Prompt</Button>
                <br></br>
                {/* <Button id="generateButton" type="submit" importance="primary" >Generate Prompt with AI</Button> */}
              </div>
            </Card>
          </div>
        </form>
        <div className='generatedPrompt'>
          <form >
            <Card padding="medium" raised rounded width="auto" height='auto'>
              <FormField label="Generated Prompt">
                <InputArea value={generatedPromptValue} onChange={handleGeneratedPromptValueChange} name="generatedPrompt" id="generatedPrompt" />
              </FormField>
              <br></br>
            </Card>
            <Button id="saveTemplateBtn" method="post" onSubmit={saveTemplate} onClick={saveTemplate} importance="primary">Save Template</Button>
            <div className='addDataCallout'>
              <Callout id="callout-additionalData" title="Note:" text="Additional data can be provided on the next page, and will used for testing of your generated prompt template." />
            </div>
            {savedTemplate === true ? (
              <div className='savedTemplateStatus'>
                <StatusLabel variant="success">Success, your template has been saved</StatusLabel>
                <Button id="runGeneratedTemplate" method="post" onClick={runSavedTemplate} importance="primary" variant='warning'>Select Models and Run Saved Template -></Button>
                <Button id="viewMyTemplates" method="post" onClick={viewMyTemplates} importance="primary" variant='warning'>View My Templates -></Button>
              </div>) : (<div></div>)
            }
          </form>
        </div>
      </div>
    </div>

  );
}

export default TemplateInputForm;