import React, { useEffect, useState } from 'react';
import {createRoot} from 'react-dom/client'
import ReactMarkdown from 'react-markdown'
import '../style/template.css'
import { Button, Card } from '@blueprism/ui-core';
import pelican from '../style/pelican.gif'
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import '../style/template.css'
import '../style/shared.css';
import { useFormValue } from '../services/FormValueProvider';


function TestTemplateResult({ callData }) {
  const [loading, setLoading] = React.useState(true)
  const [responses, setResponses] = React.useState([])
  const [resultData, setResultData] = React.useState([])
  const [value, setValue] = React.useState(0)
  const formreq = useFormValue();
  const navigate = useNavigate();
  const { task_ids } = useParams();
  
  useEffect(() => {
    setResultData(callData)
    checkResult(callData)
  }, [task_ids]);

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function checkResult(callData) {
    var callsProcessed = 0
    for (const call of callData) {
      let result = await getResult(call)
      callsProcessed++
      if (callsProcessed >= callData.length) {
        console.log("calls processed:  " + callsProcessed + " and current responses: " + JSON.stringify(responses))
        setLoading(false)
        generateResultCards()
      }
    }
    setValue(value => value + 1);
  }

  async function getResult(call) {
    await timeout(1000);
    const response = await formreq.getTask(call.task_id, localStorage.getItem("site"))
    if (response.ERROR_409) {
      await getResult(call)
    }
    else if (response.state === "SUCCESS") {
      responses.push(response)
      setResponses(responses)
      return response;
    }

  }

  function copyResult() {
    console.log("coppied")
  }
  const generateResultCards = () => {
    if (loading === false) {
      let responseHTML = (<div>
        {responses.map((m) => <div className='indvResult'>
          <Card padding="medium" width="auto" height="auto" raised rounded>
            <h3><b>Model Name: </b>{JSON.stringify(m.meta.model)}</h3>
            <Card padding="medium" width="auto" height="auto" raised >
              <p><b>Result:</b></p>
              <ReactMarkdown children={m.result} />
            </Card>
            <br></br>
            <Button id="copyResult" method="post" onClick={copyResult} importance="secondary">Copy Result</Button>
          </Card>
        </div>)}
      </div>)
      return responseHTML
    }
    else {
      return (loadingGif())
    }
  }

  const loadingGif = () => {
    return (
      <div>
        <h6>Please wait while your results are gathered</h6>
        <img src={pelican} alt="pelican loading..." />
      </div>
    )
  }

  function createTemplate(e) {
    e.preventDefault()
    navigate("/template-create")
  }

  return (
    <div className='TestTemplateResult'>
      <h3>Here is what the seleceted LLM(s) responded:</h3>
      {generateResultCards()}
      <br></br>
      <Button id="createPromptTemplate" method="post" onClick={createTemplate} importance="primary">Create Another Prompt Template</Button>
      <Button onClick={() => navigate(-1)}>Go Back</Button>
      <br></br>
    </div>
  );
}

export default TestTemplateResult;