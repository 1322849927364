import React from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
import TemplateLibrary from '../components/TemplateLibrary';
function TemplateLibraryPage() {
  return (
    <div>
      <Navbar></Navbar>
      <h2>Template Library</h2>
      <p>A compilation of ready-made prompt templates for ewase of use in data extraction scenarios</p>
      <FormValueProvider>
        <TemplateLibrary></TemplateLibrary>
      </FormValueProvider>
    </div>
  );
}

export default TemplateLibraryPage;