import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import '../index.css';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
import TemplateInputForm from '../components/TemplateInputForm';

function VersionEditPage() {
  const { version_id } = useParams();
  const template_id = version_id

  useEffect(() => {
  }, [version_id]);

  return (
    <div>
      <Navbar></Navbar>
      <FormValueProvider>
        <h2>Edit Version</h2>
        <TemplateInputForm template_id={template_id} templateStatus="versionEdit"></TemplateInputForm>
      </FormValueProvider>
    </div>
  );
}

export default VersionEditPage;