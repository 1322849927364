export const fakeTemplates=
    
    [{
        "name": "Don't Touch my Stapler Template",
        "id": "be9c52d5-e74d-4c4a-a100-b96ba1c00260",
        'created_at': '2024-03-18T13:30:13+00:00',
        'last_updated': '2024-03-19T13:30:13+00:00>',
        'versions': '4',
        'version_name': 'something',
        'version_id': 'be9c52d5-e74d-4c4a-a100-b96ba1c00260',
        'role':'Milton',
        'tone':'Disapointed',
        'expected_output':'staples',
        'context':'watch office space',
        'query':'why did you touch my stapler?',
        'generated_prompt':'I am a <role> working at <company>, please create me a <tone> response to the following email: <email>',
        'keyVal':['Role','Tone', 'Query']
    },
    {
        "name": "Barbie Girl Template",
        "id": "0d99b9e3-f89e-4bec-a9e3-9ff54083d706",
        'created_at': '2024-03-18T13:30:13+00:00',
        'last_updated': '2024-03-19T13:30:13+00:00>',
        'versions': '4',
        'version_name': 'something',
        'version_id': '0d99b9e3-f89e-4bec-a9e3-9ff54083d706',
        'role':'Ken',
        'tone':'I am kenough',
        'expected_output':'where ken would be a 10',
        'context':'mojo dojo casa house',
        'query':'am i a barbie girl?',
        'generated_prompt':'I am a <role> working at <company>, please create me a <tone> response to the following email: <email>',
        'keyVal':['Role','Expected Output', 'Query']
    },{
        "name": "Not a Chicken Template",
        "id": "7584f65c-2c09-4698-8d94-0e5aabc3aacc",
        'created_at': '2024-03-18T13:30:13+00:00',
        'last_updated': '2024-03-19T13:30:13+00:00>',
        'versions': '4',
        'version_name': 'something',
        'version_id': '7584f65c-2c09-4698-8d94-0e5aabc3aacc',
        'role':'i am turkey',
        'tone':'gobble',
        'expected_output':'gobblegobble',
        'context':'not a duck either',
        'query':'am i fowl?',
        'generated_prompt':'I am a <role> working at <company>, please create me a <tone> response to the following email: <email>',
        'keyVal':['Tone','Expected Output', 'Query']
    },{
        "name": "Im Hungry Template",
        "id": "90af326f-3704-450d-8283-57b2432ab181",
        'created_at': '2024-03-18T13:30:13+00:00',
        'last_updated': '2024-03-19T13:30:13+00:00>',
        'versions': '4',
        'version_name': 'something',
        'version_id': '90af326f-3704-450d-8283-57b2432ab181',
        'role':'chicken nugget',
        'tone':'pizza',
        'expected_output':'french fries',
        'context':'fooooooood',
        'query':'where is the food?',
        'generated_prompt':'I am a <role> working at <company>, please create me a <tone> response to the following email: <email>',
        'keyVal':['Role','Tone','Expected Output', 'Query']
    },
]
