import React from 'react';
import '../index.css';
import Navbar from '../components/Navbar';
import FormValueProvider from '../services/FormValueProvider';
import TemplateTable from '../components/TemplateTable';
function TemplatePage() {

  return (
    <div>
      <Navbar></Navbar>
      <h2>Prompt Templates</h2>
      <FormValueProvider>
        <TemplateTable tableType="templates"></TemplateTable>
      </FormValueProvider>
    </div>
  );
}

export default TemplatePage;