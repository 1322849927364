import React from 'react';
import '../index.css';
import '../style/navbar.css'

function Navbar() {
    return (
        <div className='navbar'>
            <ul>
                <li><a href="/template-create">Create Template</a></li>
                <li><a href="/template-list">View My Templates</a></li>
                <li><a href="/template-library">Template Library</a></li>
            </ul>
        </div>
    );
}

export default Navbar;