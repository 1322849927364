import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../env_data/endpoints";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (apiKey) => {
    try {
      const response = await fetch(endpoints["GET-Models"], {
        method: "GET",
        headers: {
          'x-api-key': apiKey,
          "Content-Type": "application/json",
        }
      });
      const res = await response.json();
      if (res) {
        if (res.message != "Forbidden") {
          localStorage.setItem("site", apiKey);
          navigate("/template-create");
        }
        else {
          console.log("oops")
          navigate("/oops");
        }

        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
      navigate("/oops");
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/auth");
  };

  return (
    <AuthContext.Provider value={{ token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};