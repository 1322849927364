import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import '../index.css';
import '../style/template.css'
import '../style/shared.css';
import { Stack, ToolbarButton } from '@blueprism/ui-core';
import { Table, TableToolbar, useSelectedRows } from '@blueprism/ui-pattern-table';
// import { ToolbarButton } from '@blueprism/ui-pattern-toolbar';
import { TriangleInCircle, Trash, Pencil } from '@blueprism/ui-icons';
import { ModalWindow } from '@blueprism/ui-pattern-modal';
import { useFormValue } from '../services/FormValueProvider';
import { useNavigate } from "react-router-dom";

const columns = [
  {
    header: 'Version Name',
    accessor: 'name',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  }, {
    header: 'Version Id',
    accessor: 'id',
    width: '5rem',
    disableSort: false
    // tableCell: (value) => format(value),
  },
  {
    header: 'Created',
    accessor: 'created_at',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  },
  {
    header: 'Last Updated',
    accessor: 'last_updated',
    width: '10rem',
    disableSort: false
    // tableCell: (value) => format(value),
  },
]

function TemplateVersionTable() {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [, setDeleteTemplateIds] = React.useState([])
  const [deleteTemplateNames, setDeleteTemplateNames] = React.useState([])
  const [versions, setVersions] = React.useState([])
  const [, setLoadingVersions] = React.useState(true)
  const formreq = useFormValue();
  const { template_id } = useParams();

  const {
    selectedRowIds,
    noRowsSelected,
    singleRowSelected,
    handleRowSelectionChange,
    handleCheckboxStatusChange
  } = useSelectedRows([]);

  //DELETE Actions
  function handleDeleteModalOpen(templatesToDelete) {
    let deleteTemplateNames = []
    templatesToDelete.forEach(i => deleteTemplateNames.push(i['name']))
    setDeleteTemplateNames(deleteTemplateNames)
    setOpenDeleteModal(true);
  }

  function handleDeleteModalClose() {
    setOpenDeleteModal(false)
  }

  function handleDeleteConfirmationClick() {
    handleDeleteModalClose()
  }

  function deleteSelectedTemplate(e) {
    let delete_template_ids = selectedRowIds
    let templatesToDelete = []
    delete_template_ids.forEach(i => {
      templatesToDelete.push(versions.find((j => j["id"] === i)))
    });
    setDeleteTemplateIds(delete_template_ids)
    handleDeleteModalOpen(templatesToDelete)
  }

  //EDIT actions
  function editSelectedTemplate(e) {
    //get seleted row id
    let template_id = selectedRowIds
    navigate('/version-edit/' + template_id)
  }

  //RUN Actions
  function runSelectedTemplate(e) {
    let template_id = selectedRowIds
    navigate('/template-run/' + template_id)
  }

  const getVersions = () => {
    formreq.getVersions(template_id, localStorage.getItem("site")).then((response) => {
      response.forEach((temp) => {
        var createdDate = new Date(temp.created_at)
        var lastUpdated = new Date(temp.last_updated)
        temp.created_at = createdDate.toLocaleString()
        temp.last_updated = lastUpdated.toLocaleString()
      })
      setVersions(response)
      setLoadingVersions(false)
    })

  }
  function onHeaderClick(e) {
    e.preventDefault();
  }

  useEffect(() => {
    getVersions();
  }, [template_id]);;

  return (
    <div className='TemplateVersionTable'>
      <Stack gap="base">
        <TableToolbar customContextualButtons=
          {[
            <ToolbarButton label="Test Version" icon={<TriangleInCircle />} disabled={noRowsSelected || !singleRowSelected} onClick={runSelectedTemplate} />,
            <ToolbarButton label="Edit Version" icon={<Pencil />} disabled={noRowsSelected || !singleRowSelected} onClick={editSelectedTemplate} />,
            <ToolbarButton label="Delete Version" icon={<Trash />} disabled={noRowsSelected} onClick={deleteSelectedTemplate} />
          ]} />

        <Table rowData={versions} columns={columns} sortColumns selectedIds={selectedRowIds} selectRows onRowSelectionChange={handleRowSelectionChange} onHeaderCheckboxClick={handleCheckboxStatusChange} />
      </Stack>

      <ModalWindow open={openDeleteModal} onClose={handleDeleteModalClose} modalId="modal-1" modalHeaderTitle="Delete Version?" closeIconButton="true" primaryActionText="Confirm Delete" onPrimaryActionClick={handleDeleteConfirmationClick}>
        Are you sure you want to delete the following version(s)?
        {deleteTemplateNames.map(name => (<li>{name}</li>))}
      </ModalWindow>
    </div>
  );
}

export default TemplateVersionTable;